<template>
  <div>
    <b-col style="min-width: 350px;min-height: 200px;">
      <b-card 
        :header="name"
        header-tag="header"
        tag="article"
        style="max-width: 20rem;"
        class="mb-4"
      > 
       <template #header>
         <div class="d-flex justify-content-between">
           <div >
            <b-form-text style="font-size: 1.2rem;">{{name}}</b-form-text>
           </div>
           <div>
              <b-button variant="info" v-b-tooltip.hover title="Add New File Type" class="customButtonPadding">
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
              </svg>
              </b-button>
           </div>            
         </div>
      </template>
      
      <!-- <b-card
        :title="name"
        img-src="https://picsum.photos/600/300/?image=25"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem;"
        class="mb-2"
      > -->
      <!-- <b-text>
        The months and file processed per month data should go here and button click will open pop up to select specific month to get the file 
      </b-text><br> -->
      <!-- <b-list-group>
        <b-list-group-item v-for="item in monthData" :key="item.id" class="d-flex justify-content-between align-items-center">
          {{item.Month}}
          <b-badge variant="primary" pill>{{item.NoOfFiles}}</b-badge>
        </b-list-group-item>
      </b-list-group> -->
      <b-container class="">
        <b-row>
          <b-col>
            Sales
          </b-col>
          <b-col>
            <div>
             <b-link href="#" class="customAnchorTag">Review</b-link>
            </div>
          </b-col>
          <b-col>
            <div>
             <b-link class="customAnchorTag" href="/IntermediaryMapping/ReviewFileMapping">Update</b-link>
            </div>
          </b-col>
          <div class="w-100"></div>
          <b-col>
            Rental
          </b-col>
          <b-col>
            <div>
             <b-link href="#" class="customAnchorTag">Review</b-link>
            </div>
          </b-col>
          <b-col>
            <div>
             <b-link href="/IntermediaryMapping/ReviewFileMapping" class="customAnchorTag">Update</b-link>
            </div>
          </b-col>
          <div class="w-100"></div>
          <!-- <b-col>Jul - 0</b-col>
          <b-col>Aug - 0</b-col>
          <b-col>Sep - 0</b-col>
          <div class="w-100"></div>
          <b-col>Oct - 1</b-col>
          <b-col>Nov - 0</b-col>
          <b-col>Dec - 22</b-col> -->
          
        </b-row>
      </b-container>
        <!-- <b-button
          v-b-tooltip.hover
          title="Download file for specific month"
          v-b-modal="modalId"
          variant="outline-primary"
          class="mt-2"
          >Get File</b-button
        >
        <b-modal :id="modalId" title="BootstrapVue">
          <p class="my-4">Date picker and download button will be here</p>
        </b-modal> -->
      </b-card>
    </b-col>
  </div>
</template>

<script>

export default {
  name : "IntermediaryCard",
  props:{
  name: String,
  id: Number,
  monthData: {Array,default : []}
  },
  computed: {
    modalId() {
      return `modal-${this.id}`;
    }
  }
};
</script>

<style scoped>
.customButtonPadding{
  padding: 0.300rem 0.50rem !important;
}
a.customAnchorTag {
  font-size: 0.75rem !important;
}
</style>
