<template>
 <b-container>
     <b-form @submit="onSubmit" @reset="onReset" v-if="show">
     <!-- <div class="d-flex mb-2">
         <b-link href="/IntermediaryMapping">Back</b-link>
     </div> -->
        <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-1 variant="info">Update Existing Intermediary</b-button>
        </b-card-header>
        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-container fluid>
                <b-row class="my-1 mt-2">
                    <b-col sm="3" class="ml-n2">
                    <label for="input-default">Intermediary Name :</label>
                    </b-col>
                    <b-col sm="9" class="ml-2">
                    <b-form-input id="input-1" v-model="form.IntermediaryName" disabled></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="my-1 mt-2">
                    <b-col sm="3">
                    <label for="input-default">Intermediary File Type :</label>
                    </b-col>
                    <b-col sm="9">
                    <b-form-input id="input-1" v-model="form.IntermediaryFileType" disabled></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="my-1 mt-2">
                    <b-col sm="4">
                    <label for="input-default">Send Invoice To Customer :</label>
                    </b-col>
                    <b-col sm="8">
                    <b-form-radio-group id="radio-group-1" v-model="form.SendInvoiceToCustomer" :options="options" 
                     name="radio-options"></b-form-radio-group>
                    </b-col>
                </b-row>                 
            </b-container>
            <!-- <b-form-group id="input-group-1" label="Intermediary Name :" label-for="input-1">           
              <b-form-input id="input-1" v-model="form.IntermediaryName" disabled></b-form-input>
            </b-form-group>
                
            <b-form-group id="input-group-2" label="Your Name:" label-for="input-2">
              <b-form-input id="input-2" v-model="form.IntermediaryFileType" placeholder="Intermediary File Type"  disabled></b-form-input>
            </b-form-group> -->
        </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-2 variant="info">Column Header Mapping Details</b-button>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
            <b-card-text>abc</b-card-text>
            </b-card-body>
        </b-collapse>
        </b-card>

         <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-3 variant="info">File Related Details</b-button>
            </b-card-header>
            <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                <b-card-text>abc</b-card-text>
                </b-card-body>
            </b-collapse>
         </b-card>
     </div>
      <b-button type="submit" class="m-2" variant="primary">Submit</b-button>
      <b-button type="reset" class="m-2" variant="danger">Reset</b-button>
    </b-form>
    <!-- <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card> -->
 </b-container>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          IntermediaryName: 'BIBLIOTECH',
          IntermediaryFileType: 'Sales',
          SendInvoiceToCustomer : true
        },
        show: true,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        alert(JSON.stringify(this.form))
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.IntermediaryName = ''
        this.form.IntermediaryFileType = ''      
        this.form.SendInvoiceToCustomer = false  
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.col-sm-3 {
    flex: 0 0 20% !important;
}
.col-sm-4 {
    flex: 0 0 22.333333% !important;
    max-width: 22.333333% !important;
}
.col-sm-8 {
    flex: 0 0 14.666667% !important;
    max-width: 14.666667% !important;
}
</style>
