<template>
  <div class="home">    
    <b-container>
      <b-nav-form class="mb-4" @submit.prevent="search">
            <b-form-input
              size="sm"
              class="mr-sm-2"
              placeholder="Search"
              v-model="searchText"
            ></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit"
              >Search</b-button
            ></b-nav-form>
      <b-row class="d-flex justify-content-between">
        <card
          :name="job.name"
          :id="job.id"
          :monthData ="job.monthData"
          v-for="job in getDisplayJobs"
          :key="job.id"
        ></card>
      </b-row>
      <b-pagination
        v-model="currentPage"
        :total-rows="getRows"
        :per-page="perPage"
        first-text="First"
        prev-text="Prev"
        next-text="Next"
        last-text="Last"
        @input="paginate(currentPage)"
      ></b-pagination>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import FileProcessingCard from "@/components/FileProcessingCard.vue";
import { mapGetters } from "vuex";

export default {
  name: "FileProcessingSummary",
  async mounted() {
    this.getRecords();
  },
  data() {
    return {
      currentPage: 1,
      perPage: 6,
      searchText: ""
    };
  },
  components: { "card": FileProcessingCard },
  computed: {
    ...mapGetters(["getRows", "getDisplayJobs"])
  },
  methods: {
    paginate(currentPage) {
      this.$store.dispatch("paginate", { currentPage, perPage: this.perPage });
    },
    search() {
      //console.log(this.searchText);
      this.$store.dispatch("search", { text: this.searchText });
    },
    async getRecords() {
      await this.$store.dispatch("fetchJobs");
    }
  }
};
</script>
<style lang="scss" scoped>
// b-card {
// padding: 10px;
// }
</style>
