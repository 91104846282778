<template>
  <div>
    <b-col>
      <b-card 
        :header="name"
        header-tag="header"
        tag="article"
        style="max-width: 20rem;"
        class="mb-4"
      >
       <template #header>
         <div class="d-flex justify-content-between">
           <div >
            <b-form-text style="font-size: 1.2rem;">{{name}}</b-form-text>
         </div>
          <div >
            <b-checkbox v-b-tooltip.hover title="Status" switch size="lg"></b-checkbox></div>
         </div>
      </template>
      
      <!-- <b-card
        :title="name"
        img-src="https://picsum.photos/600/300/?image=25"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem;"
        class="mb-2"
      > -->
      <!-- <b-text>
        The months and file processed per month data should go here and button click will open pop up to select specific month to get the file 
      </b-text><br> -->
      <!-- <b-list-group>
        <b-list-group-item v-for="item in monthData" :key="item.id" class="d-flex justify-content-between align-items-center">
          {{item.Month}}
          <b-badge variant="primary" pill>{{item.NoOfFiles}}</b-badge>
        </b-list-group-item>
      </b-list-group> -->
      <b-container class="">
        <b-row>
          <b-col>Jan - 1</b-col>
          <b-col>Feb - 2</b-col>
          <b-col>Mar - 0</b-col>
          <div class="w-100"></div>
          <b-col>Apr - 1</b-col>
          <b-col>May - 2</b-col>
          <b-col>Jun - 0</b-col>
          <div class="w-100"></div>
          <b-col>Jul - 0</b-col>
          <b-col>Aug - 0</b-col>
          <b-col>Sep - 0</b-col>
          <div class="w-100"></div>
          <b-col>Oct - 1</b-col>
          <b-col>Nov - 0</b-col>
          <b-col>Dec - 22</b-col>
          
        </b-row>
      </b-container>
        <b-button
          v-b-tooltip.hover
          title="Download file for specific month"
          v-b-modal="modalId"
          variant="outline-primary"
          class="mt-2"
          >Get File</b-button
        >
        <b-modal :id="modalId" title="BootstrapVue">
          <p class="my-4">Date picker and download button will be here</p>
        </b-modal>
      </b-card>
    </b-col>
  </div>
</template>

<script>
export default {
  props:{
  name: String,
  id: Number,
  monthData: {Array,default : []}
  },
  computed: {
    modalId() {
      return `modal-${this.id}`;
    }
  }
};
</script>

<style scoped></style>
